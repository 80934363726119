@import url(https://fonts.googleapis.com/css2?family=Handlee&family=Raleway:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Neucha&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Neucha&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Neucha&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat&display=swap);
body {
  font-family: 'Raleway', sans-serif;
  font-size: 1.2em;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}


.fade-enter, .fade-exit {
  transition: all .5s;
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
}

.fade-enter, .fade-exit-active {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  z-index: 1;
}

.title-t{
  font-family: 'Neucha', cursive;
  font-size: 2.6rem;
  color: #000000;
  -webkit-text-decoration: #422600 solid underline;
  text-decoration: #422600 solid underline;
  -webkit-text-underline-position: auto;
  text-underline-position: auto;
}

.title-underline{
  font-family: 'Neucha', cursive;
  font-size: 2.4rem;
  color: #422600;
  -webkit-text-decoration: #422600 solid underline;
  text-decoration: #422600 solid underline;
  -webkit-text-underline-position: auto;
  text-underline-position: auto;
}

.title-color{
  font-family: 'Neucha', cursive;
  font-size: 2rem;
  color: #422600;
}

.ticker-text{
  font-family: 'Neucha', cursive;
  font-size: 1.5rem;
  color: #422600;
}

.container-xl {
  max-width: 1500px;
}

.scrollTop{
  color: white;
  -webkit-filter: invert(20%);
          filter: invert(20%);
  transition: all .5s;
  transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
}

.scrollTop:hover{
  -webkit-filter: invert(50%);
          filter: invert(50%);
  
}

blockquote{
  font-size: 1em;
  width:auto !important;
  font-family: 'Handlee', cursive;
  color: #000000;
  padding:10px;
}


.markdown h1, .markdown h2, .markdown h3{
  text-align: center;
  margin-bottom: 1.4em;
}

.fa-plus {
  color: #422600;
}

.hoverable{
  cursor: pointer;
}

.hoverable:hover{
  color: #a89780;
}

.row.padded {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.collapseHeader {
  font-family: 'Neucha', cursive;
  
  border-bottom: #422600 solid;
  margin-bottom: 1rem;
}

.collapseHeader > h3 {
  font-size: xx-large;
}

.draw-border {
  
  box-shadow: inset 1px 1px 6px #a89780;
  color: #ffffff;
  transition: color 0.25s 0.0833333333s;
  position: relative;
}
.draw-border::before, .draw-border::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: '';
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
}
.draw-border::before {
  border-bottom-width: 6px;
  border-left-width: 6px;
}
.draw-border::after {
  border-top-width: 6px;
  border-right-width: 6px;
}
.draw-border:hover {
  color: #f7ebda;
}
.draw-border:hover::before, .draw-border:hover::after {
  border-color: #f7ebda;
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}
.draw-border:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}
.draw-border:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

.btn-animated {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font-family: 'Neucha', cursive;
  font-size: 1.4rem;
  padding: 1em 2em;
  letter-spacing: 0.05rem;
}
.btn-animated:focus {
  outline: 2px dotted #a89780;
}

img[alt$=">"] {
  float: right;
  max-width: 40%;
  height: auto;
  padding: 1rem;
}

img[alt$="<"] {
  float: left;
  max-width: 40%;
  height: auto;
  padding: 1rem;
}


@media (min-width: 320px) { 
  img[alt$=">"] {max-width: 100%;}
  img[alt$="<"] {max-width: 100%;}
}
@media (min-width: 768px) {
  img[alt$=">"] {max-width: 50%;}
  img[alt$="<"] {max-width: 50%;}
}
@media (min-width: 992px) { 
  img[alt$=">"] {max-width: 40%;}
  img[alt$="<"] {max-width: 40%;}
}
@media (min-width: 1200px) { 
  img[alt$=">"] {max-width: 40%;}
  img[alt$="<"] {max-width: 40%;}
}

@media (min-width: 1920px) { 
  img[alt$=">"] {max-width: 50%;}
  img[alt$="<"] {max-width: 50%;}
}
@media (min-width: 992px) {
    .animate {
      animation-duration: 0.3s;
      -webkit-animation-duration: 0.3s;
      animation-fill-mode: both;
      -webkit-animation-fill-mode: both;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
    100% {
      transform:translateY(0rem);
      opacity: 1;
    }
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes slideIn {
    0% {
      -webkit-transform: transform;
      -webkit-opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      -webkit-opacity: 1;
    }
    0% {
      -webkit-transform: translateY(1rem);
      -webkit-opacity: 0;
    }
  }
  
  .slideIn {
    -webkit-animation-name: slideIn !important;
    animation-name: slideIn  !important;
  }

  .navbar {
    font-family: 'Neucha', cursive;
    box-shadow: 0 6px 4px -2px rgba(0,0,0,.2);
    font-size: medium;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #422600;
  }

  .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover, .navbar-nav .show, .navbar-nav .active {
    color: #a89780;
  }

  .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
    color: #a89780;
}

  .navbar a {
    color: #422600;
  }

  .dropdown-menu {
    border-radius: 0;
    background-color:rgba(66, 38, 0, 0.95);
    margin-top: 18px;
  }

  .dropdown-item {
    font-size: medium;
    color: #a89780 !important;
  }

  .dropdown-item:hover {
    background-color: #a89780;
    color: #422600 !important;
  }

  @media (device-width:1280px) {
    .navbar {
      font-size: small ;
    }
  }

  @media (device-width:1024px) {


    .navbar a {
      font-size: 0.85em ;
    }
  }
  

  @media (device-width:360px) {
    .navbar-brand {
      max-width: 15em !important;
    }
    .navbar-toggler {
      right: 0 !important;
      position: absolute !important;
      margin-right: 40px;
      top:36px;
    }
  }

  @media (device-width:320px) {
    .navbar-brand {
      max-width: 150px;
    }
    .navbar-toggler {
      right: 0;
      position: absolute;
      margin-right: 30px;
      top:26px;
    }
  }
h1.carousel-font, h2.carousel-font{
  font-family: 'Neucha', cursive;
}

.carousel-item {
    height: 100vh;
    min-height: 350px;
    background: no-repeat center center scroll;
    background-size: cover;
  }

.carousel-item.one {
    background: url(/static/media/background-overlay0.2c7967a9.jpg);
    background-size: cover;

}

.carousel-caption {
    transform: translateY(-50%);
    bottom: 0;
    top: 50%;
  }

.carousel-citation{
  font-family: 'Caveat', cursive;
  font-size: xx-large;
  width: 40%;
  margin: auto;
} 

  @media (min-width: 360px) { 
    .carousel-caption {top: 45%;}
    .carousel-caption h1 { font-size: 3em;}
    .carousel-citation{width: auto; font-size: x-large;} 
  }

  @media (min-width: 375px) { 
    .carousel-caption {top: 45%;}
    .carousel-caption h1 { font-size: 3em;}
    .carousel-citation{width: auto; font-size: x-large;} 
  }
  @media (min-width: 768px) {
    .carousel-caption {top: 50%;}
    .carousel-caption h1 { font-size: 3 em;}
    .carousel-citation{width: auto; font-size: xx-large;} 
  }
  @media (min-width: 992px) { 
    .carousel-caption {top: 45%;}
    .carousel-caption h1 { font-size: 3.8em;}
    .carousel-citation{width: 60%; font-size: xx-large;} 
  }
  @media (min-width: 1200px) { 
    .carousel-caption {top: 45%; }
    .carousel-caption h1 { font-size: 5.0em;}
    .carousel-citation{width: 40%; font-size: xx-large;} 
  }

  @media (min-width: 1920px) { 
    .carousel-caption {top: 48%; }
    .carousel-caption h1 { font-size: 6em;}
    .carousel-citation{width: 40%; font-size: xx-large;} 
  }

.homeCard {
    margin-top: 77px;
    min-height: 92vh;
    background: url(/static/media/background-overlay0.2c7967a9.jpg);
    background-size: cover;
}


.card a {
    color: #422600;
}
.card a:hover {
    color: #a89780;
}

.card-text {
    font-family: 'Neucha', cursive;
}

.card-text.large{
    font-size: 1.7rem;
}

.card-text.small {
    font-size: smaller;
}
.card {
    max-width: 20vw;
    height: 30vh;
    margin: auto;
}

.card-body{
    height: 8vh;
}



.fa-undo {
    cursor: pointer;
    color:#422600;
}

.fa-undo:hover {
    color:#a89780;
}

@media (max-width: 375px) {
    .gridded { padding-left:15%;padding-top:3%;}
    .card {max-width: 70vw; max-height: 70vh;}
  }
@media (max-width: 992px) {
    .row.padded { padding:5%;}
  }

@media (min-width: 360px) { 
.card-text.small {font-size: 0.8rem;}
.card-text.large{ font-size: 1.4rem;}
.card-img-top {height:35vh;}
.card {max-width: 80vw; height:45vh;}
}

@media (min-width: 375px) and (max-width: 767px) { 
.card-text.small {font-size: 0.8rem;}
.card-text.large{ font-size: 1.4rem;}
.card-img-top {height:30vh;}
.card {max-width: 85vw; height:40vh;}
}
@media (min-width: 768px) {
.card-text.small {font-size: 0.6rem;}
.card-text.large{ font-size: 1.1rem;}
.card-img-top {max-height:25vh;}
.card {max-width: 60vw; max-height:30vh;}
}
@media (min-width: 992px) { 
.card-text.small {font-size: 0.6rem;}
.card-text.large{ font-size: 1.1rem;}
.card-img-top {max-height:20vh;}
.card {max-width: 60vw; max-height: 30vh;}
}
@media (min-width: 1200px) { 
.card-text.small {font-size: 0.6rem;}
.card-text.large{ font-size: 1rem;}
.card-img-top {min-height:15vh;}
.card {max-width: 17vw; max-height: 30vh;}
}

@media (min-width: 1920px) { 
.card-text.small {font-size: 1.2rem;}
.card-text.large{ font-size: 1.7rem;}
.card-img-top {min-height:23vh;}
}


.masthead {
    font-family: 'Neucha', cursive;
    height: 60vh;
    min-height: 40hv;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 3%;
    font-size: xx-large;
    color: whitesmoke;
    box-shadow: 0 6px 4px -2px #422600;
  }

.lead.headerSection {
    font-family: 'Caveat', cursive;
    font-size: x-large;
    width: 80%;
    margin: auto;
}

.masthead.bg1{
  background-image: url(/static/media/1.b63319ec.jpg);
}

.masthead.bg2{
  background-image: url(/static/media/2.6f91310e.jpg);
}

.masthead.bg3{
  background-image: url(/static/media/3.06da5426.jpg);
}

.masthead.bg4{
  background-image: url(/static/media/6.b27cd17d.jpg);
}

.masthead.bg5{
  background-image: url(/static/media/5.8b991a1b.jpg);
}

.masthead.bg6{
  background-image: url(/static/media/4.3f389505.jpg);
}

.masthead.bg7{
  background-image: url(/static/media/7.f9d46194.jpg);
}

.masthead.bg8{
  background-image: url(/static/media/8.45dc641f.jpg);
}

.masthead.bg9{
  background-image: url(/static/media/9.d672b8d7.jpg);
}

.masthead.bg10{
  background-image: url(/static/media/10.39a583d9.jpg);
}

.masthead.bg11{
  background-image: url(/static/media/11.2f26e8a4.jpg);
}

.masthead.bg12{
  background-image: url(/static/media/12.290b1dc7.jpg);
}

.masthead.bg13{
  background-image: url(/static/media/13.c3310fe2.jpg);
}


@media (max-width:380px) {
  .masthead {
    margin-top: 20%;
    height: 100vh;
  }
  .lead.headerSection {
    font-family: 'Caveat', cursive;
    font-size: large;
    width: 90%;
    margin: auto;
  }

}
.card {
    border-radius: 0!important;
}

.faq {
    font-family: 'Neucha', cursive;
    border-bottom: #422600 solid;
    margin-bottom: 1rem;
    padding: 1rem;
    transition: all .4s;
    transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    
}

.faq > h3 {
    font-size: xx-large;
  }

.faq:hover {
    box-shadow: 0 4px 4px -2px #422600;
}



.card.form{
    border:0;
}

.card.form .card-title {
    color: rgba(5, 0, 46, 0.808);
}
.stretched-list {
    max-width: 60vw;
    margin: auto;
    padding-bottom: 4vh;
}
.stretched-list h5 {
    font-family: 'Neucha', cursive;
    font-size: x-large;
}

.stretched-list div {
    font-size: smaller;
    padding-right: 2rem;
}

li.borderless {
    border: 0 none;
    margin-bottom: 1rem;
}
footer {
    background: #2c2826;
    color: white;
    
  }
  
  footer a {
    color: #fff;
    font-size: 10px;
    transition-duration: 0.2s;
  }
  
  footer a:hover {
    color: #FA944B;
    text-decoration: none;
  }
  
  .copy {
    font-size: 12px;
    padding: 10px;
    border-top: 1px solid #FFFFFF;
  }
  
  .footer-middle {
    padding-top: 2em;
    color: white;
  }
  
  ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
  }
  
  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }
  
  
  .social-network a.icoFacebook:hover {
    background-color: #3B5998;
  }
  
  .social-network a.icoInstagram:hover {
    background-color: #b83dbd; 
  }
  
  .social-network a.icoFacebook:hover i,
  .social-network a.icoInstagram:hover i {
    color: #fff;
  }
  
  .social-network a.socialIcon:hover,
  .socialHoverClass {
    color: #44BCDD;
  }
  
  .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    border-radius: 50%;
    text-align: center;
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
  
  .social-circle li i {
    margin: 0;
    line-height: 20px;
    text-align: center;
  }
  
  .social-circle li a:hover i,
  .triggeredHover {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    transition: all 0.2s;
  }
  
  .social-circle i {
    color: #595959;
    transition: all 0.8s;
  }
  
  .social-network a {
    background-color: #F9F9F9;
  }
  
