@import url('https://fonts.googleapis.com/css2?family=Neucha&display=swap');


@media (min-width: 992px) {
    .animate {
      animation-duration: 0.3s;
      -webkit-animation-duration: 0.3s;
      animation-fill-mode: both;
      -webkit-animation-fill-mode: both;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
    100% {
      transform:translateY(0rem);
      opacity: 1;
    }
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes slideIn {
    0% {
      -webkit-transform: transform;
      -webkit-opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      -webkit-opacity: 1;
    }
    0% {
      -webkit-transform: translateY(1rem);
      -webkit-opacity: 0;
    }
  }
  
  .slideIn {
    -webkit-animation-name: slideIn !important;
    animation-name: slideIn  !important;
  }

  .navbar {
    font-family: 'Neucha', cursive;
    box-shadow: 0 6px 4px -2px rgba(0,0,0,.2);
    font-size: medium;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #422600;
  }

  .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover, .navbar-nav .show, .navbar-nav .active {
    color: #a89780;
  }

  .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
    color: #a89780;
}

  .navbar a {
    color: #422600;
  }

  .dropdown-menu {
    border-radius: 0;
    background-color:rgba(66, 38, 0, 0.95);
    margin-top: 18px;
  }

  .dropdown-item {
    font-size: medium;
    color: #a89780 !important;
  }

  .dropdown-item:hover {
    background-color: #a89780;
    color: #422600 !important;
  }

  @media (device-width:1280px) {
    .navbar {
      font-size: small ;
    }
  }

  @media (device-width:1024px) {


    .navbar a {
      font-size: 0.85em ;
    }
  }
  

  @media (device-width:360px) {
    .navbar-brand {
      max-width: 15em !important;
    }
    .navbar-toggler {
      right: 0 !important;
      position: absolute !important;
      margin-right: 40px;
      top:36px;
    }
  }

  @media (device-width:320px) {
    .navbar-brand {
      max-width: 150px;
    }
    .navbar-toggler {
      right: 0;
      position: absolute;
      margin-right: 30px;
      top:26px;
    }
  }